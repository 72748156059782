// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';

const configOptions = {
  apiKey: "AIzaSyApRXUNpbLc85NgucOLxoyoyg2XosfC_O0",
  authDomain: "bingo-ed2b9.firebaseapp.com",
  databaseURL: "https://bingo-ed2b9.firebaseio.com",
  projectId: "bingo-ed2b9",
  storageBucket: "bingo-ed2b9.appspot.com",
  messagingSenderId: "278422022480",
  appId: "1:278422022480:web:6ca1ce36a8e0ce0a392455",
  measurementId: "G-4L91P7V7DY"
};

firebase.initializeApp(configOptions);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.analytics();

export default firebase;
