import {axiosData} from "@/store/api/axios";

const state = () => ({
  planes: JSON.parse(localStorage.getItem('planes')) || null,
  plan: JSON.parse(localStorage.getItem('plan')) || null,

});

const getters = {
  getPlanes(state) {
    return state.planes;
  },
  getPlan(state) {
    return state.plan;
  },
};

const actions = {
  setPlan({ commit }, payload) {
    commit('SET_PLAN', payload);
    payload
        ? localStorage.setItem('plan', JSON.stringify(payload))
        : localStorage.removeItem('plan');
  },
  setPlanes({ commit }, payload) {
    commit('SET_PLANES', payload);
    payload
        ? localStorage.setItem('planes', JSON.stringify(payload))
        : localStorage.removeItem('planes');
  },
  async damePlanes(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Planes');
    try {
      const data = await axiosData(
          true,
          'plan',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  
};

const mutations = {
  SET_PLANES(state, payload) {
    state.planes = payload;
    localStorage.setItem('planes', JSON.stringify(payload));
  },
  SET_PLAN(state, payload) {
    state.plan = payload;
    localStorage.setItem('plan', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
