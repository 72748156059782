import Vue from "vue";
import Vuex from "vuex";

import sessionModule from './store/modules/session';
import uiModule from './store/modules/ui';
import planModule from './store/modules/plan';
import partidaModule from './store/modules/partida';
import cartillaModule from './store/modules/cartilla';
import playerModule from './store/modules/player';

Vue.use(Vuex);
const getDefaultState = () => {
    return {
        isMobile: false,
        routes: null,
        routesObject: null,
        showSpinner: false,
        showLoginbox:false,
        showInscribete: false,
    }
}
const state = getDefaultState()

export default new Vuex.Store({
    state,
  getters: {
  },
  mutations: {
      updateResolution(state, payload) {
          state.isMobile = payload;
      },
  },
  actions: {
      updateResolution: ({ commit }, payload) =>
          commit('updateResolution', payload),
  },
    plugins:[],
    modules:{
        session:sessionModule,
        ui: uiModule,
        plan: planModule,
        partida:partidaModule,
        cartilla: cartillaModule,
        player:playerModule
    }
});
