const state = () => ({
  error: null,
  success: null,
  isLoaderActive: false,
  isLoaderBtnActive: false,
  isLoaderTableActive: false,
  isLoaderSkeletonActive: false,
  textLoading: null,
  isDialogOpen: false,
  dialogTab: 'login', // 'login' o 'register'
});
const getters = {
  isDialogOpen: state => state.isDialogOpen,
  dialogTab: state => state.dialogTab,
};
const actions = {
  setError({ commit }, payload) {
    commit('SET_ERROR', payload);
  },
  openLogin({ commit }) {
    commit('SET_DIALOG_TAB', 'login');
    commit('SET_DIALOG_STATE', true);
  },
  openRegister({ commit }) {
    commit('SET_DIALOG_TAB', 'register');
    commit('SET_DIALOG_STATE', true);
  },
  closeDialog({ commit }) {
    commit('SET_DIALOG_STATE', false);
  },
  setTextLoading({ commit }, payload) {
    commit('SET_TEXTLOADING', payload);
  },
  setSuccess({ commit }, payload) {
    commit('SET_SUCCESS', payload);
  },
  clearNotifications({ commit }) {
    commit('SET_ERROR', null);
    commit('SET_SUCCESS', null);
  },
  handleLoader({ commit }, bool) {
    commit('HANDLE_LOADER', bool);
  },
  handleLoaderBtn({ commit }, bool) {
    commit('HANDLE_LOADER_BTN', bool);
  },
  handleLoaderTable({ commit }, bool) {
    commit('HANDLE_LOADER_TABLE', bool);
  },
  handleLoaderSkeleton({ commit }, bool) {
    commit('HANDLE_LOADER_SKELETON', bool);
  },
};
const mutations = {
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_DIALOG_STATE(state, payload) {
    state.isDialogOpen = payload;
  },
  SET_DIALOG_TAB(state, payload) {
    state.dialogTab = payload;
  },
  SET_TEXTLOADING(state, payload) {
    state.textLoading = payload;
  },
  SET_SUCCESS(state, payload) {
    state.success = payload;
  },
  HANDLE_LOADER: function (state, bool) {
    state.isLoaderActive = bool;
  },
  HANDLE_LOADER_BTN: function (state, bool) {
    state.isLoaderBtnActive = bool;
  },
  HANDLE_LOADER_TABLE: function (state, bool) {
    state.isLoaderTableActive = bool;
  },
  HANDLE_LOADER_SKELETON: function (state, bool) {
    state.isLoaderSkeletonActive = bool;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
