import {axiosData} from "@/store/api/axios";

const state = () => ({
  cartillas: JSON.parse(localStorage.getItem('cartillas')) || null,
  cartilla: JSON.parse(localStorage.getItem('cartilla')) || null,

});

const getters = {
  getCartillas(state) {
    return state.cartillas;
  },
  getCartilla(state) {
    return state.cartilla;
  },
};

const actions = {
  setCartilla({ commit }, payload) {
    commit('SET_CARTILLA', payload);
    payload
        ? localStorage.setItem('cartilla', JSON.stringify(payload))
        : localStorage.removeItem('cartilla');
  },
  setCartillas({ commit }, payload) {
    commit('SET_CARTILLAS', payload);
    payload
        ? localStorage.setItem('cartillas', JSON.stringify(payload))
        : localStorage.removeItem('cartillas');
  },
  async dameCartillas(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Jugadores');
    try {
      const data = await axiosData(
          true,
          'cartillas',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async borrarCartilla(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'cartillas/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async dameCartilla(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'cartillas/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
 
  async grabarCartilla(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'cartillas/create',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async updateCartilla(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'cartillas/'+payload.id,
          payload.payload,
          'put',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_CARTILLAS(state, payload) {
    state.cartillas = payload;
    localStorage.setItem('cartillas', JSON.stringify(payload));
  },
  SET_CARTILLA(state, payload) {
    state.cartilla = payload;
    localStorage.setItem('cartilla', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
