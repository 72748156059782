import {axiosData} from "@/store/api/axios";

const state = () => ({
  players: JSON.parse(localStorage.getItem('players')) || null,
  player: JSON.parse(localStorage.getItem('player')) || null,

});

const getters = {
  getPlayers(state) {
    return state.players;
  },
  getPlayer(state) {
    return state.player;
  },
};

const actions = {
  setPlayer({ commit }, payload) {
    commit('SET_PLAYER', payload);
    payload
        ? localStorage.setItem('player', JSON.stringify(payload))
        : localStorage.removeItem('player');
  },
  setPlayers({ commit }, payload) {
    commit('SET_PLAYERS', payload);
    payload
        ? localStorage.setItem('players', JSON.stringify(payload))
        : localStorage.removeItem('players');
  },
  async damePlayers(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Jugadores');
    try {
      const data = await axiosData(
          true,
          'players',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async borrarPlayer(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'players/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async damePlayer(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'players/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async damePlayerByDni(_,dni){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'players/dni/'+dni,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async grabarPlayer(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'players/create',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async updatePlayer(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'players/'+payload.id,
          payload.payload,
          'put',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_PLAYERS(state, payload) {
    state.players = payload;
    localStorage.setItem('players', JSON.stringify(payload));
  },
  SET_PLAYER(state, payload) {
    state.player = payload;
    localStorage.setItem('player', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
